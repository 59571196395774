<template>
  <div>
    <v-sheet
      v-if="loading"
      :color="`grey`"
      class="pa-3"
    >
      <v-skeleton-loader
        class="mx-auto"
        type="text-field, table, data-table"
      >
      </v-skeleton-loader>
    </v-sheet>

    <v-card v-else>
      <snack-view
        :message="snackbar.message"
        :activate="snackbar.active"
        :bg-color="snackbar.color"
      ></snack-view>
      <div>
        <v-card-title class="text-uppercase font-weight-bold">
          Liste d'utilisateurs
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-row>
            <v-col>
              <v-text-field
                v-model="search"
                :append-icon="icons.mdiMagnify"
                label="Rechercher"
                single-line
                hide-details
                dense
                outlined
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <!-- Table -->
        <v-data-table
          :headers="headers"
          :items="users"
          :search="search"
          :options="dataOptions"
          :items-per-page="15"
          sort-by="id"
          class="table-kitchen-sink"
        >
          <!-- product -->
          <template #[`item.avatar`]="{item}">
            <div class="align-center">
              <v-avatar
                size="40px"
                color="secondary"
                class="v-avatar-dark-bg primary--text mr-4"
              >
                {{ avatarText(item.first_name+" "+item.last_name) }}
              </v-avatar>
            </div>
          </template>

          <!-- product -->
          <template #[`item.fullName`]="{item}">
            <div class="align-center row">
              <div class="col-4">
                <v-avatar
                  size="40px"
                  color="secondary"
                  class="v-avatar-dark-bg primary--text mr-0"
                >
                  {{ avatarText(item.first_name+" "+item.last_name) }}
                </v-avatar>
              </div>
              <div class="col-8">
                <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.first_name+" "+item.last_name }} </span>
                <span class="text--primary font-italic text-sm">{{ item.phone }}</span>
              </div>
            </div>
          </template>

          <template #[`item.client`]="{item}">
            <div class="d-flex align-center">
              <span>
                <b class="d-block font-weight-semibold text-truncate text--primary">{{ item.customer.name }}</b>
              </span>
            </div>
          </template>

          <template #[`item.email`]="{item}">
            <div class="align-center">
              <span class="font-weight-semibold text-truncate text--primary">{{ item.email }}</span>
            </div>
          </template>

          <template #[`item.role`]="{item}">
            <div class="align-center">
              <span class="d-block font-weight-semibold text-truncate text--primary">{{ item.roles[0].name }}</span>
              <span class=" text-truncate text--primary font-italic smal"><span>Login : </span>{{ item.login }}</span>
            </div>
          </template>
          <!-- action -->
          <template #[`item.actions`]="{ item }">
            <div class="col-12 w-">
              <div v-if="isRoleAdminGetionnaire()">
                <v-btn
                  class="ml-1 text-capitalize"

                  outlined
                  x-small
                  color="info"
                  @click="editItem(item)"
                >
                  <v-icon
                    x-small
                    class="me-1"
                  >
                    {{ icons.mdiPencilOutline }}
                  </v-icon>
                  Modifier
                </v-btn>
                <v-btn
                  v-if="item.state==1"
                  class="ml-1 text-capitalize"

                  outlined
                  x-small
                  color="#F80102"
                  @click="openDialog(item)"
                >
                  <v-icon
                    small
                  >
                    {{ icons.mdiCloseCircle }}
                  </v-icon>
                  <span v-if="item.state==1">
                    Désactiver
                  </span>
                </v-btn>
                <v-btn
                  v-if="item.state==0"
                  class="ml-1 text-capitalize"
                  outlined
                  x-small
                  color="#0E8112"
                  @click="togleUserStatus(item)"
                >
                  <v-icon
                    small
                  >
                    {{ icons.mdiCheck }}
                  </v-icon>
                  <span v-if="item.state==0">
                    Activer
                  </span>
                </v-btn>
                <v-btn
                  class="ml-1 text-capitalize"
                  outlined
                  x-small
                  color="#FE9A2E"
                  @click="ressetPassword(item)"
                >
                  <v-icon
                    x-small
                    class="me-1"
                  >
                    {{ icons.mdiReload }}
                  </v-icon>
                  Réinitialiser
                </v-btn>
              </div>
              <div v-if="isRoleRoot()">
                <v-btn
                  class="ml-1 text-capitalize"
                  x-small
                  color="primary"
                  outlined
                  @click="connectUser(item)"
                >
                  <v-icon
                    small
                  >
                    fas fa-log-in
                  </v-icon>
                  Se connecter
                </v-btn>
                <v-btn
                  class="ml-1 text-capitalize"
                  outlined
                  x-small
                  color="#FE9A2E"
                  @click="ressetPassword(item)"
                >
                  <v-icon
                    x-small
                    class="me-1"
                  >
                    {{ icons.mdiReload }}
                  </v-icon>
                  Réinitialiser
                </v-btn>
              </div>
            </div>
          </template>

          <template #[`item.state`]="{item}">
            <div class="d-flex align-center">
              <div class=" flex-column ms-3">
                <v-chip
                  v-if="item.state==1"
                  class="ma-2 text-center pr-5 pl-5"
                  color="#F1FFE8FF"
                  outlined
                >
                  <b class="success--text">Actif</b>
                </v-chip>
                <v-chip
                  v-else-if="item.state==0"
                  class="ma-2 text-center pr-5 pl-5"
                  color="#ffcfcf"
                  outlined
                >
                  <b class="error--text">Inactif</b>
                  <v-tooltip
                    right
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        class="ml-2 bold text-capitalize"
                        icon
                        v-bind="attrs"
                        primary
                        x-small
                        v-on="on"
                      >
                        <h2>?</h2>
                      </v-btn>
                    </template>
                    <span>{{ item.comment }} </span>
                  </v-tooltip>
                </v-chip>
                <v-chip
                  v-else-if="item.state==-1"
                  class="ma-2 text-center pr-5 pl-5"
                  color="#FE9A2E"
                  outlined
                >
                  <b>Bloqué</b>
                </v-chip>
              </div>
            </div>
          </template>
        </v-data-table>

        <!-- dialog confirmation réinitialisation -->
        <v-dialog
          v-model="dialogResset"
          max-width="500px"
          persistent
        >
          <v-card>
            <v-card-title>
              <span>Etes vous sûr de vouloir réinitialiser le mot de passe de {{ selectedLine.first_name+" "+selectedLine.last_name }}?</span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                outlined
                class="text-capitalize"
                @click="close"
              >
                Annuler
              </v-btn>
              <v-btn
                color="primary"
                class="text-capitalize"
                @click="resetPwd"
              >
                Confirmer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- dialog du motif de désactivation -->
        <v-dialog
          v-model="dialogStop"
          max-width="500px"
          persistent
        >
          <v-card>
            <v-card-title>
              <span>Veuillez entrer le motif</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-col
                cols="16"
                md="10"
              >
                <v-textarea
                  v-model="comment"
                  label="Motif"
                  :rules="LocalNameRules"
                  outlined
                  dense
                  hide-details="auto"
                ></v-textarea>
              </v-col>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                outlined
                class="text-capitalize"
                @click="close"
              >
                Annuler
              </v-btn>
              <v-btn
                color="primary"
                class="text-capitalize"
                @click="togleUserStatus()"
              >
                Confirmer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- dialog affichage du nouveau mot de passe -->
        <v-dialog
          v-model="dialogPassword"
          max-width="1000px"
          persistent
        >
          <v-card>
            <v-card-title>
              <span class="d-sm-flex align-center justify-center">Nouveau de mot de passe </span>
            </v-card-title>
            <v-divider></v-divider>

            <v-card-text>
              <div>
                <span><h3>NB: Vous devez copiez ce mot de passe pour le communiquer à {{ selectedLine.first_name+" "+selectedLine.last_name }}.
                  Si vous fermez cette boîte de dialogue sans le copier il vous faudra recommencer le processus</h3> </span>
                <span
                  v-model="textToCopy"
                  readonly
                ><h3> Le nouveau mot de passe est:</h3></span>
                <div>
                  <v-row class="d-sm-flex align-center justify-center">
                    <v-col cols="auto">
                      <v-text-field
                        v-model="textToCopy"
                        readonly
                        disabled
                      >
                        {{ passwordMessage }}
                      </v-text-field>
                    </v-col>
                    <v-col cols="auto">
                      <v-icon
                        x-large
                        class="me-1"
                        @click="copyToClipboard"
                      >
                        {{ icons.mdiContentCopy }}
                      </v-icon>
                    </v-col>
                    <span style="color: green">{{ copyMessage }}</span>
                  </v-row>
                </div>
              </div>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                outlined
                class="text-capitalize"
                @click="close"
              >
                Annuler
              </v-btn>
              <v-btn
                color="primary"
                class="text-capitalize"
                @click="close"
              >
                Ok
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- dialog édition -->
        <v-dialog
          v-model="dialog"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span>Modifier un utilisateur</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              <v-container>
                <v-form
                  ref="updateUser"
                >
                  <v-row>
                    <v-col
                      cols="12"
                      offset-md="2"
                      md="8"
                    >
                      <v-text-field
                        v-model="editedItem.first_name"
                        label="Nom"
                        :rules="LocalNameRules"
                        outlined
                        dense
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      offset-md="2"
                      md="8"
                    >
                      <v-text-field
                        v-model="editedItem.last_name"
                        label="Prénom"
                        :rules="LocalNameRules"
                        outlined
                        dense
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      offset-md="2"
                      md="8"
                    >
                      <v-text-field
                        v-model="editedItem.email"
                        label="Email"
                        :rules="LocalNameRules"
                        outlined
                        dense
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                    <v-col
                      cols="12"
                      offset-md="2"
                      md="8"
                    >
                      <v-text-field
                        v-model="editedItem.phone"
                        label="Téléphone"
                        :rules="LocalNameRules"
                        outlined
                        dense
                        hide-details="auto"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </v-form>
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                outlined
                class="text-capitalize"
                @click="close"
              >
                Annuler
              </v-btn>
              <v-btn
                color="primary"
                class="text-capitalize"
                @click="save"
              >
                Enregistrer
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>

        <!-- dialog connection -->
        <v-dialog
          v-model="dialogConnect"
          max-width="500px"
        >
          <v-card>
            <v-card-title>
              <span>Confirmation</span>
            </v-card-title>
            <v-divider></v-divider>
            <v-card-text>
              Êtes-vous sûr de vouloir vous connecter au compte de l'utilisateur <b>{{ editedItem.first_name }} {{ editedItem.last_name!==null?editedItem.last_name:"" }}</b> ?
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                outlined
                class="text-capitalize"
                @click="close"
              >
                Annuler
              </v-btn>
              <v-btn
                color="primary"
                class="text-capitalize"
                @click="saveConnectUser"
              >
                Se connecter
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
    </v-card>
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { isRoleAdminGetionnaire, isRoleRoot } from '@/helpers/constants/roles'
import {
  mdiMagnify,
  mdiDeleteOutline,
  mdiPencilOutline,
  mdiEyeOutline,
  mdiCloseCircle,
  mdiReload,
  mdiCheck,
  mdiContentCopy,
} from '@mdi/js'
import data from '@/views/locaux/datatable'
import Http from '@/helpers/http'
import { useRouter } from '@core/utils'
import Vue from 'vue'
import moment from 'moment'

import SnackView from '@/views/SnackView'
import { avatarText } from '@core/utils/filter'
import Session from '@/helpers/session'
import useDynamicConstant from '@/helpers/useDynamicConstant'

Vue.prototype.moment = moment

export default {
  components: {
    SnackView,
  },
  setup() {
    const loading = true
    const search = ref('')
    const { router } = useRouter()
    const users = ref([])
    const selectedLine = ref([])
    const snackbar = ref({
      active: false,
      message: '',
      color: 'success',
    })
    const textToCopy = ref('')

    // Règle de validation d'un champ
    const LocalNameRules = [
      v => !!v || 'Ce champ est réquis',
    ]
    const goToUSerEdit = user => {
      // eslint-disable-next-line no-shadow,radix
      const userId = parseInt(user.id)

      router.push({ fullName: 'Utilisateur', params: { id: userId } })
    }
    const orderNum = item => users.value.indexOf(item) + 1
    const passwordMessage = ref('')
    const copyMessage = ref('')

    return {
      icons: {
        mdiMagnify,
        mdiDeleteOutline,
        mdiPencilOutline,
        mdiEyeOutline,
        mdiCloseCircle,
        mdiReload,
        mdiCheck,
        mdiContentCopy,
      },
      search,
      goToUSerEdit,
      loading,
      selectedLine,
      passwordMessage,
      textToCopy,
      copyMessage,
      comment: '',

      LocalNameRules,
      orderNum,
      avatarText,
      router,
      headers: [
        /* { text: ' ', value: 'avatar', divider: false, align: 'center' }, */
        {
          text: 'Utilisateur', value: 'fullName', divider: true, align: 'center',
        },
        {
          text: 'Email', value: 'email', divider: true, align: 'center',
        },
        {
          text: 'Client', value: 'client', divider: true, align: 'center',
        },
        {
          text: 'Rôle', value: 'role', divider: true, align: 'center',
        },
        {
          text: 'Etat', value: 'state', divider: true, align: 'center',
        },
        {
          text: 'Actions', value: 'actions', sortable: false, divider: true, align: 'center',
        },
      ],
      dataOptions: {
        sortBy: ['id'],
      },
      users,
      editedIndex: -1,
      editedItem: {},
      defaultItem: {},
      dialog: false,
      dialogResset: false,
      dialogStop: false,
      dialogConnect: false,
      dialogPassword: false,
      snackbar,
    }
  },
  watch: {
    dialog(val) {
      // eslint-disable-next-line no-unused-expressions
      val || this.close()
    },
  },
  mounted() {
    moment.locale('fr')
    this.loadUsers()
  },
  methods: {
    isRoleAdminGetionnaire,
    isRoleRoot,
    initialize() {
      this.userList = JSON.parse(JSON.stringify(data))
    },

    editItem(item) {
      this.editedIndex = this.users.indexOf(item)
      // eslint-disable-next-line no-param-reassign
      this.editedItem = { ...item }
      this.dialog = true
    },

    ressetPassword(item) {
      this.selectedLine = item
      this.dialogResset = true
    },

    copyToClipboard() {
      const el = this.textToCopy
      navigator.clipboard.writeText(el)
        .then(() => {
          this.copyMessage = 'Texte copié avec succès !'
        })
        .catch(error => {
          this.copyMessage = 'Erreur lors de la copie du texte.'
        })
    },

    connectUser(item) {
      this.editedIndex = this.users.indexOf(item)
      this.editedItem = { ...item }
      this.dialogConnect = true
    },

    close() {
      this.copyMessage = ''
      this.comment = ''
      this.dialog = false
      this.dialogStop = false
      this.dialogConnect = false
      this.dialogResset = false
      this.dialogPassword = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    save() {
      if (this.$refs.updateUser.validate()) {
        const segment = JSON.parse(localStorage.getItem('defaultContrat')).id
        this.editedItem.idUser = this.editedItem.id
        this.snackbar.active = false
        Http.post(`update-user-data/${segment}`, this.editedItem)
          .then(response => {
            if (response.status === 200) {
              this.loadUsers()
              this.snackbar.message = response.message
              this.snackbar.active = true
            }
          })
          .catch(error => {
            console.log(error)
          })

        this.close()
      }
    },

    resetPwd() {
      const params = { id: this.selectedLine.id }
      this.snackbar.active = false
      Http.post('/password-reset-admin', params)
        .then(response => {
          if (response.status === 200) {
            this.dialogPassword = true
            this.snackbar.message = response.message
            this.snackbar.active = true
            this.passwordMessage = response.data
            this.textToCopy = response.data
          }
        })
        .catch(error => {
          console.log(error)
        })

      this.dialogResset = false
    },

    saveConnectUser() {
      const params = {}
      params.userId = this.editedItem.id

      this.snackbar.active = false
      Http.post('signin-as', params)
        .then(rep => {
          Session.set('accessToken', rep.data.token)
          localStorage.setItem('visu', rep.data.visu)

          Http.get(
            'get-user-data',
          ).then(response => {

            localStorage.setItem('contrats', JSON.stringify(response.data.contrats))
            localStorage.setItem('customer', JSON.stringify(response.data.customer))
            localStorage.setItem('defaultContrat', JSON.stringify(response.data.defaultContrat))
            localStorage.setItem('permissions', JSON.stringify(response.data.permissions))
            localStorage.setItem('defaultRole', JSON.stringify(response.data.defaultRole))
            localStorage.setItem('menu', JSON.stringify(response.data.menu))
            localStorage.setItem('roles', JSON.stringify(response.data.roles))

            const sesPer = { Debut: moment().startOf('month').format('YYYY-MM-DD'), Fin: moment().format('YYYY-MM-DD') }
            localStorage.setItem('periode', JSON.stringify(sesPer))

            const userData = response.data.user
            Session.set('userData', JSON.stringify(userData))

            const { ROLE_SUPER_ADMIN } = useDynamicConstant()

            const role = userData.roles[0]

            // if (role.id !== ROLE_SUPER_ADMIN && Storage.isSet('defaultContrat')) {
            if (role.id !== ROLE_SUPER_ADMIN) {
              Http.get(`get-info-from-wholesaler/${response.data.defaultContrat.id}`)
                // eslint-disable-next-line no-shadow
                .then(response => {
                  const clientInfo = response.data[0]
                  localStorage.setItem('clientInfo', JSON.stringify(clientInfo))

                  this.router.push('/')
                  // eslint-disable-next-line no-restricted-globals
                  location.reload()
                })
                .catch(error => {
                  console.log(error)
                })

              Http.get('get-api-status')
                // eslint-disable-next-line no-shadow
                .then(rep => {
                  Session.set('API_CANAL_STATUS', rep.data.status)
                }).catch(error => {
                  console.error(error)
                })
            } else {
              this.router.push('/')
              // eslint-disable-next-line no-restricted-globals
              location.reload()
            }
          })
        })
        .catch(error => {
          console.log(error)
        })

      this.dialogConnect = false
    },

    openDialog(item) {
      this.dialogStop = true
      this.editedItem = { ...item }

    },

    togleUserStatus(item) {
      let currentItem = null
      if (item === undefined) {
        currentItem = this.editedItem
        if (this.comment.trim() === '') {
          return
        }
      } else {
        currentItem = { ...item }
      }

      currentItem.idUser = currentItem.id
      currentItem.state = currentItem.state == 1 ? 0 : 1
      currentItem.comment = this.comment
      const segment = JSON.parse(localStorage.getItem('defaultContrat')).id
      this.snackbar.active = false

      Http.post(`update-user-data/${segment}`, currentItem)
        .then(response => {
          if (response.status === 200) {
            this.dialogStop = false
            this.comment = ''
            this.loadUsers()
            this.snackbar.message = response.message
            this.snackbar.active = true
          }
        })
        .catch(error => {
          console.log(error)
        })
    },

    loadUsers() {
      this.loading = true
      const customerId = localStorage.getItem('customer') ? JSON.parse(localStorage.getItem('customer')).id : null

      if (customerId) {
        Http.get(`get-users/${customerId}`)
          .then(async response => {
            if (response.status === 200) {
              this.users = await response.data
              this.loading = false
            }
          })
          .catch(error => {
            console.error('Erreur :', error)
          })
      } else {
        // Http.get('get-all-users')
        Http.get(`get-users/${this.$route.params.id}`)
          .then(async response => {
            if (response.status === 200) {
              this.users = await response.data
              this.loading = false
            }
          })
          .catch(error => {
            console.error('Erreur :', error)
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.table-kitchen-sink ::v-deep {
  .v-data-table-header {
    white-space: nowrap;
  }
}

td{
  text-align: left !important;
}
</style>
